import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        width: 200,
        height: 66,
    },
    primary: {
        fill: theme.palette.primary.main
    },
    secondary: {
        fill: theme.palette.secondary.main
    },
    green: {
        fill: '#2ecb3e;'
    }
});

const LogoSvg = (props) => {
    const {classes} = props;
    return (
        <SvgIcon className={classes.root} viewBox="0 0 1500.000000 493.000000">
            <g transform="translate(0.000000,493.000000) scale(0.100000,-0.100000)"
               fill="#113b5e" stroke="none">
                <path d="M416 4914 c-190 -46 -356 -214 -401 -406 -13 -54 -15 -310 -15 -1855
l0 -1793 230 0 230 0 0 1405 0 1405 500 0 500 0 0 230 0 230 -500 0 -500 0 0
144 c0 127 2 147 19 167 l19 24 489 5 488 5 3 228 2 227 -502 -1 c-398 0 -515
-4 -562 -15z"/>
                <path d="M8320 3720 l0 -420 -776 0 c-854 0 -832 1 -958 -62 -83 -42 -193
-154 -234 -238 -63 -127 -63 -133 -60 -959 l3 -746 26 -66 c35 -92 69 -146
125 -203 70 -70 139 -113 227 -141 l78 -25 1015 0 1014 0 -2 1638 -3 1637
-227 3 -228 2 0 -420z m-26 -911 l26 -31 0 -694 c0 -776 3 -744 -68 -763 -24
-7 -279 -11 -725 -11 -734 0 -740 0 -766 48 -16 30 -15 1405 1 1435 25 48 1
46 775 47 l731 0 26 -31z"/>
                <path d="M11832 2283 l3 -1858 24 -60 c65 -163 174 -270 340 -334 64 -24 75
-25 349 -29 l282 -4 0 231 0 231 -235 0 c-269 0 -287 4 -304 68 -8 25 -11 617
-11 1825 l0 1787 -225 0 -225 0 2 -1857z"/>
                <path d="M1205 3285 c-178 -48 -314 -169 -382 -341 l-28 -69 -3 -769 c-2 -665
-1 -776 13 -830 49 -196 200 -349 395 -401 84 -22 1579 -22 1665 0 183 48 342
207 390 390 23 88 23 1542 0 1630 -49 187 -206 340 -400 390 -83 21 -1571 21
-1650 0z m1582 -469 l28 -24 0 -711 0 -711 -28 -27 -27 -28 -698 -3 c-383 -2
-712 0 -731 3 -18 4 -44 17 -57 30 l-24 24 0 711 0 711 25 24 24 25 730 0 730
0 28 -24z"/>
                <path d="M3550 2080 l0 -1220 225 0 225 0 2 966 3 966 28 24 28 24 730 0 730
0 24 -25 25 -24 0 -966 0 -965 230 0 230 0 -2 1008 -3 1007 -24 60 c-65 162
-174 271 -336 336 l-60 24 -1027 3 -1028 3 0 -1221z"/>
                <path d="M9060 2333 c0 -1065 -1 -1045 62 -1173 40 -84 160 -204 240 -243 122
-58 104 -57 943 -57 839 0 821 -1 943 57 80 39 200 159 240 243 63 128 62 108
62 1173 l0 967 -230 0 -230 0 0 -955 c0 -1066 4 -1005 -68 -1024 -51 -15
-1383 -15 -1434 0 -72 19 -68 -42 -68 1024 l0 955 -230 0 -230 0 0 -967z"/>
                <path d="M12560 2328 c0 -826 2 -972 15 -1027 46 -193 186 -342 385 -407 51
-17 113 -19 818 -22 l762 -3 0 -167 c0 -149 -2 -171 -20 -199 -11 -18 -21 -34
-22 -35 -2 -2 -334 -5 -738 -8 l-735 -5 0 -225 0 -225 725 -3 c506 -2 746 1
793 9 202 33 391 210 436 409 7 30 11 529 11 1458 l0 1412 -225 0 -225 0 0
-935 c0 -1020 2 -989 -56 -1013 -22 -9 -203 -12 -722 -10 l-694 3 -24 28 -24
28 0 950 0 949 -230 0 -230 0 0 -962z"/>
            </g>
        </SvgIcon>
    )
};

export default withStyles(styles)(LogoSvg);