import React from 'react';
import './App.css';
import Apper from './Apper';
import PaperSheet from './PaperSheet';
import Paper from '@material-ui/core/Paper';
import LogoSvg from './logo';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
    root: {
        marginTop: 25,
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    apper: {
        // minHeight: 375,
        // maxHeight: 700
        // height: 500
    }
});


function App (props) {
    const {classes} = props;

        return (
            <React.Fragment>
                <CssBaseline/>
                <Container maxWidth="xl" className={classes.root}>
                    <Paper className={classes.paper}>
                    <Grid container spacing={3}>
                        <LogoSvg/>

                        <Grid item xs={12}>

                    <Apper className={classes.apper}/>
                        </Grid>
                    </Grid>
                    </Paper>
                </Container>
            </React.Fragment>
        );

}
export default withStyles(styles)(App);
// export default App;
