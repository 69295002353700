import React, { Component } from 'react';
import randomColor from 'randomcolor';
import TagCloud from 'react-tag-cloud';
// import CloudItem from './CloudItem';

const randomInt = (min, max) => {
    min = Math.ceil(min);
    max = ~~(max);
    return ~~(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
}

// const fontSize = () => fontSizeIt()
// const color = randomColor({count: 10})

const styles = {
    large: {
        fontFamily: 'sans-serif',
        //fontSize: () => Math.round(Math.random() * 50) + 16,
        // fontSize,
        // color,
        padding: 5,
    },
    small: {
        opacity: 0.7,
        fontSize: 12
    }
};

const rotateIt = () => randomInt(-45, 45);
const fontSizeIt = (min, max) => randomInt(min, max);

const getStyle = (family) => ({
    fontFamily: family,
    fontSize: () => fontSizeIt(18,34),
    color: randomColor({hue: '#5e1a11'})
})

// const Rotated = (props) => {
//     return (
//         <div style={getStyle('Roboto')} rotate={()=>rotateIt()}>Node.js</div>
//     )
// }

class Apper extends Component {
    componentDidMount() {
        setInterval(() => {
            this.forceUpdate();
        }, 2000);
    }

    render() {
        return (
            <div className='app-outer'>
                <div className='app-inner'>
                    <TagCloud
                        className='tag-cloud'
                        style={{
                            fontFamily: 'sans-serif',
                            fontSize: () => fontSizeIt(20,50),
                            color: ()=>randomColor({hue: '#113b5e'}),
                            padding: 5,
                        }}
                    >

                        <div style={styles.large}>Software Development</div>
                        <div style={styles.large}>Systems Integration</div>
                        <div style={styles.large}>Application Development</div>
                        <div style={styles.large}>Project Management</div>
                        <div style={styles.large}>System Design</div>
                        <div style={styles.large}>Team Augmentation</div>
                        <div style={styles.large}>System Architecture</div>
                        <div style={styles.large}>Data Security</div>

                        {/*<CloudItem style={getStyle('Roboto')} rotate={rotateIt()} >Node.js</CloudItem>*/}
                        <div style={getStyle('Roboto')} rotate={()=>rotateIt()}>Node.js</div>
                        <div style={getStyle('sans-serif')} rotate={()=>rotateIt()}>Javascript</div>
                        <div style={getStyle('Roboto')} rotate={()=>rotateIt()}>NPM</div>
                        <div style={getStyle('sans-serif')} rotate={()=>rotateIt()}>React</div>
                        <div style={getStyle('Roboto')} rotate={()=>rotateIt()}>SQL</div>
                        <div style={getStyle('sans-serif')} rotate={()=>rotateIt()}>ReST</div>
                        <div style={getStyle('Roboto')} rotate={()=>rotateIt()}>No SQL</div>
                        <div style={getStyle('sans-serif')} rotate={()=>rotateIt()}>Big DATA</div>
                        <div style={getStyle('Roboto')} rotate={()=>rotateIt()}>Web Services</div>

                    </TagCloud>
                </div>
            </div>
        );
    }
}

export default Apper;
